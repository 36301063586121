import "./src/styles/global.css"
import React from 'react'
import { RootLayout } from './src/RootLayout'

export const wrapRootElement = ({ element }) => (
  <RootLayout>{element}</RootLayout>
)

// export const onRouteUpdate = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "instant"  // ou "auto"
//     })
// }  