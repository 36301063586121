import React, { useState, Fragment, createContext, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Stars, X, CheckCircle } from 'lucide-react';
import { useForm, ValidationError } from '@formspree/react';

const WaitlistContext = createContext();

export const useWaitlist = () => {
  const context = useContext(WaitlistContext);
  if (!context) {
    throw new Error('useWaitlist must be used within WaitlistProvider');
  }
  return context;
};

const SuccessMessage = () => (
  <div className="flex flex-col items-center justify-center py-8 px-4">
    <div className="mb-6 relative">
      <div className="absolute inset-0 bg-green-500/20 blur-xl rounded-full"></div>
      <CheckCircle className="w-16 h-16 text-green-500 relative z-10" />
    </div>
    <h4 className="text-2xl font-light text-gray-200 mb-3 text-center">
      You're on the list!
    </h4>
    <p className="text-gray-400 text-center mb-6 max-w-sm">
      Thank you for joining our waitlist. We'll keep you updated on our progress and let you know when we're ready to welcome you.
    </p>
    <div className="flex items-center gap-2 text-sm text-gray-500">
      <Stars className="w-4 h-4" />
      <span>You'll hear from us soon</span>
    </div>
  </div>
);

const WaitlistForm = ({ onSuccess }) => {
  const [state, handleSubmit] = useForm("xeoqkyaz");

  if (state.succeeded) {
    onSuccess?.();
    return <SuccessMessage />;
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="name" className="block text-sm text-gray-400 mb-1">Name</label>
        <input
          id="name"
          type="text"
          name="name"
          className="w-full rounded-lg bg-gray-800/50 border border-gray-700 px-3 py-2 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
        <ValidationError prefix="Name" field="name" errors={state.errors} className="text-red-500 text-sm mt-1" />
      </div>

      <div>
        <label htmlFor="email" className="block text-sm text-gray-400 mb-1">Work email</label>
        <input
          id="email"
          type="email"
          name="email"
          className="w-full rounded-lg bg-gray-800/50 border border-gray-700 px-3 py-2 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} className="text-red-500 text-sm mt-1" />
      </div>

      <div>
        <label htmlFor="company" className="block text-sm text-gray-400 mb-1">Company name</label>
        <input
          id="company"
          type="text"
          name="company"
          className="w-full rounded-lg bg-gray-800/50 border border-gray-700 px-3 py-2 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
        <ValidationError prefix="Company" field="company" errors={state.errors} className="text-red-500 text-sm mt-1" />
      </div>

      <div>
        <label htmlFor="phone" className="block text-sm text-gray-400 mb-1">
          Phone number <span className="text-gray-600">(optional)</span>
        </label>
        <input
          id="phone"
          type="tel"
          name="phone"
          className="w-full rounded-lg bg-gray-800/50 border border-gray-700 px-3 py-2 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <ValidationError prefix="Phone" field="phone" errors={state.errors} className="text-red-500 text-sm mt-1" />
      </div>

      <button
        type="submit"
        disabled={state.submitting}
        className={`w-full py-2 px-4 rounded-lg bg-gradient-to-r from-blue-500 to-green-500 text-white font-medium 
          hover:from-blue-600 hover:to-green-600 focus:outline-none focus:ring-2 focus:ring-blue-500 
          focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-200
          ${state.submitting ? 'opacity-75 cursor-not-allowed' : ''}`}
      >
        {state.submitting ? 'Submitting...' : 'Join waitlist'}
      </button>
      
      <ValidationError errors={state.errors} className="text-red-500 text-sm" />
    </form>
  );
};

const WaitlistPopup = ({ open, onOpenChange }) => {
  const handleSuccess = () => {
    setTimeout(() => onOpenChange(false), 15000);
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => onOpenChange(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-md transform overflow-hidden rounded-xl bg-gray-900 border border-gray-800/50 p-6 text-left align-middle shadow-xl transition-all relative">
                <div className="absolute inset-0 bg-gradient-to-b from-blue-500/5 to-green-500/5 pointer-events-none" />
                
                <Dialog.Title as="h3" className="text-xl font-light text-gray-200 mb-2 relative">
                  Join the waitlist
                </Dialog.Title>
                <p className="text-gray-400 mb-6 relative">
                  Get early access to our beta program and start building the future of blockchain infrastructure.
                </p>

                <button
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-400 transition-colors"
                  onClick={() => onOpenChange(false)}
                >
                  <X className="h-4 w-4" />
                </button>

                <div className="relative">
                  <WaitlistForm onSuccess={handleSuccess} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const WaitlistProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const openWaitlist = () => setIsOpen(true);
  const closeWaitlist = () => setIsOpen(false);

  return (
    <WaitlistContext.Provider value={{ openWaitlist, closeWaitlist }}>
      {children}
      <WaitlistPopup open={isOpen} onOpenChange={setIsOpen} />
    </WaitlistContext.Provider>
  );
};

export default WaitlistProvider;