import React, { useEffect } from 'react'
import { WaitlistProvider } from './components/WaitlistProvider'
import Header from './components/Header'
import Footer from './components/Footer'

export const RootLayout = ({ children }) => {
  return (
    <WaitlistProvider>
      <meta name="viewport" content="width=device-width, initial-scale=0.8"/>
      <div className="flex flex-col min-h-screen bg-gray-900 text-white relative overflow-hidden font-sans">
        <Header />
        <main className="flex-grow">
          {children}
        </main>
        <Footer />
      </div>
    </WaitlistProvider>
  )
}