import React, { useState } from 'react';
import { Link } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, ChevronRight } from 'lucide-react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    { path: '/features', label: 'Features' },
    { path: '/pricing', label: 'Pricing' },
    { path: '/about', label: 'About' }
  ];

  const containerVariants = {
    open: { 
      opacity: 1,
      height: "auto",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24,
        staggerChildren: 0.1
      }
    },
    closed: { 
      opacity: 0,
      height: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24,
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    }
  };

  const itemVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    },
    closed: {
      opacity: 0,
      x: -20,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24
      }
    }
  };

  return (
    <header className="bg-gray-900/95 backdrop-blur-sm text-white fixed w-full z-50 border-b border-gray-800">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link 
          to="/" 
          className="flex items-center space-x-2 group"
        >
          <span className="font-semibold text-2xl bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent group-hover:to-blue-400 transition-all duration-300">
            BaaS
          </span>
        </Link>

        <nav className="hidden md:flex items-center space-x-8">
          {menuItems.map(item => (
            <Link
              key={item.path}
              to={item.path}
              className="relative font-light hover:text-blue-400 transition-colors py-1 group"
            >
              {item.label}
              <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-400 transition-all duration-300 group-hover:w-full"/>
            </Link>
          ))}
          {/* Uncomment if you want to add the Get Started button
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-6 rounded-lg transition-colors">
            Get Started
          </button>
          */}
        </nav>

        <button
          onClick={() => setIsOpen(!isOpen)}
          className="md:hidden p-2 hover:bg-gray-800 rounded-lg transition-colors"
          aria-label="Toggle menu"
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={isOpen ? 'close' : 'menu'}
              initial={{ opacity: 0, rotate: -90 }}
              animate={{ opacity: 1, rotate: 0 }}
              exit={{ opacity: 0, rotate: 90 }}
              transition={{ duration: 0.2 }}
            >
              {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </motion.div>
          </AnimatePresence>
        </button>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="md:hidden border-t border-gray-800"
            initial="closed"
            animate="open"
            exit="closed"
            variants={containerVariants}
          >
            <div className="px-4 py-4 space-y-4">
              {menuItems.map((item, index) => (
                <motion.div key={item.path} variants={itemVariants}>
                  <Link
                    to={item.path}
                    className="flex items-center justify-between p-2 text-lg font-light hover:text-blue-400 transition-colors rounded-lg hover:bg-gray-800/50 group"
                    onClick={() => setIsOpen(false)}
                  >
                    <span>{item.label}</span>
                    <ChevronRight className="w-5 h-5 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all duration-200" />
                  </Link>
                  {index < menuItems.length - 1 && (
                    <div className="h-px bg-gray-800 my-2" />
                  )}
                </motion.div>
              ))}
              {/* Uncomment if you want to add the Get Started button
              <motion.div variants={itemVariants}>
                <button className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-6 rounded-lg transition-colors flex items-center justify-center space-x-2">
                  <span>Get Started</span>
                  <ChevronRight className="w-5 h-5" />
                </button>
              </motion.div>
              */}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;